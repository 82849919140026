export const CarouselWizard = {
  arrows: false,
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 2000,
      settings: { slidesToShow: 5 },
    },
    {
      breakpoint: 1400,
      settings: { slidesToShow: 4 },
    },
    {
      breakpoint: 960,
      settings: { slidesToShow: 2 },
    },
  ],
};
