import React from 'react';
import { classNames } from 'helpers';
import './VideoBackground.scss';

export const VideoBackground = props => {
  const { className, url, children } = props;
  const classes = classNames('video-background', className);

  return (
    <div className={classes}>
      <div className="video-background-overlay" />
      <video className="video-background-source" autoPlay loop playsInline muted>
        <source src={url} type="video/mp4" />
      </video>
      {children}
      {/* <div className="video-background-mask">
        <svg
          viewBox="0 0 100 100"
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          fill="white"
        >
          <path d="M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z" />
        </svg>
      </div> */}
    </div>
  );
};
