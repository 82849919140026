import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { classNames } from 'helpers';
import { CarouselLayouts } from './CarouselLayouts';
import './CarouselLayouts/styles.scss';

export const Carousel = (props) => {
  const { layout, className, children } = props;
  const settingsLayout = (layout && CarouselLayouts[layout]) || {};
  const settings = { ...CarouselLayouts.Default, ...settingsLayout };
  const classes = classNames('Carousel', layout, className, { Dots: settings.dots });

  return (
    <div className={classes}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.string,
  className: PropTypes.string,
};

Carousel.defaultProps = {
  layout: 'Default',
  className: null,
};
