import React from 'react';
import YouTube from 'react-youtube';
import { classNames } from 'helpers';
import './Video.scss';

export const Video = props => {
  const { className, children, url, background } = props;
  const classes = classNames('video', className);

  // Video Options
  // https://developers.google.com/youtube/player_parameters
  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 1,
      disablekb: 1,
      // modestbranding: 1,
    },
  };

  // Get Video ID
  const getVideoId = videoUrl => {
    if (!videoUrl) return null;
    const baseUrl = 'https://www.youtube.com/watch?v=';
    return videoUrl.split(baseUrl).pop();
  };

  return <YouTube videoId={getVideoId(url)} opts={videoOptions} onEnd={event => event.target.playVideo()} />;
};
