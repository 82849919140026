import React from 'react';
import { Icon } from 'components';
import { classNames } from 'helpers';
import './LinkIndicator.scss';

export const LinkIndicator = (props) => {
  const { className } = props;
  const classes = classNames('link-indicator', className);
  return (
    <div className={classes}>
      <Icon title="Chevron" type="svg" />
    </div>
  );
};
