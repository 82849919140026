import React from 'react';

export const DefaultInput = (props) => {
  const { type = 'text', registeredField, onChange, onBlur, placeholder, min, max } = props;

  return (
    <input
      {...registeredField}
      type={type}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      min={min}
      max={max}
    />
  );
};
