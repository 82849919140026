export const CarouselGallery = {
  dots: true,
  slidesToShow: 2,
  centerMode: true,
  // variableWidth: true,
  responsive: [
    {
      breakpoint: 2000,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 1200,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 960,
      settings: { slidesToShow: 1 },
    },
  ],
};
