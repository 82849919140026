import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import './Logo.scss';

export const Logo = () => {
  const data = useStaticQuery(graphql`
    query SiteLogo {
      site {
        siteMetadata {
          title
          description
          logo
        }
      }
    }
  `);

  const { title, description, logo } = data.site.siteMetadata;
  return (
    <Link className="logo" to="/" title={title}>
      <img src={logo} title={title} alt={description} />
    </Link>
  );
};
