import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Header, Footer } from 'components';
import 'typeface-montserrat';
import 'typeface-open-sans';
import '../../tools/sass/global/styles.scss';
import './Layout.scss';

export const Layout = (props) => {
  const { children, isHero, hasForm } = props;

  // Query links
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          site
          siteSlug
          title
          socials {
            name
            url
          }
        }
      }
      article: allPrismicArticle {
        nodes {
          uid
          data {
            title {
              text
            }
          }
        }
      }
      location: allPrismicLocation {
        nodes {
          uid
          data {
            title {
              text
            }
          }
        }
      }
      page: allPrismicPage {
        nodes {
          uid
          data {
            title {
              text
            }
          }
        }
      }
      project: allPrismicProject {
        nodes {
          uid
          data {
            title {
              text
            }
          }
        }
      }
      service: allPrismicService {
        nodes {
          uid
          data {
            title {
              text
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Header data={data} isHero={isHero} hasForm={hasForm} />
      <main className={data.site.siteMetadata.siteSlug}>{children}</main>
      <Footer data={data} />
    </>
  );
};
