import React, { useState, useLayoutEffect } from 'react';

export const useWindowLockScroll = () => {
  const [scrollLock, setScrollLock] = useState(false);
  const isClient = typeof window === 'object';
  useLayoutEffect(() => {
    if (!isClient) return false;
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (scrollLock) {
      document.body.style.overflow = 'hidden';
      setScrollLock(true);
    } else {
      document.body.style.overflow = originalStyle;
      setScrollLock(false);
    }

    return () => (document.body.style.overflow = originalStyle);
  }, [scrollLock]);

  return [setScrollLock];
};
