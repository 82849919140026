import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'helpers';
import { Icon } from 'components';
import './CarouselArrow.scss';

export const CarouselArrow = (props) => {
  const { className, onClick } = props;
  const classes = classNames(' CarouselArrow', className);

  const onClickHandler = () => {
    if (onClick) onClick();
  };

  return (
    <div className={classes}>
      <button type="button" onClick={onClickHandler}>
        <Icon title="ArrowLeft" />
      </button>
    </div>
  );
};

CarouselArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CarouselArrow.defaultProps = {
  className: null,
  onClick: null,
};
