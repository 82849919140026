import React, { useState } from 'react';
import { Link, Icon } from 'components';
import { classNames, useWindowLockScroll } from 'helpers';
import { MenuIcon } from './MenuIcon/MenuIcon';
import './MenuHamburger.scss';
import './MenuHorizontal.scss';
import './Menu.scss';

const MenuItem = ({ text, to, className, children, show = true }) => {
  const [showDropdown, setDropdown] = useState(false);
  if (!show || show === undefined || show === 'undefined') return null;
  const onActionHandler = () => {
    setDropdown(!showDropdown);
  };

  const classes = classNames(className, 'menu-item-title', { showDropdown });

  return (
    <div className="menu-item">
      <div
        className="menu-item-text"
        onPointerEnter={onActionHandler}
        onClick={onActionHandler}
        onPointerLeave={onActionHandler}
      >
        {to ? (
          <Link className={classes} to={to} activeClassName="active">
            {text}
          </Link>
        ) : (
          <span className={classes}>
            {children && <Icon title="ArrowDown" />}
            {text}
          </span>
        )}
      </div>
      {children && (
        <div className="menu-item-dropdown">
          <div className="menu-item-dropdown-wrapper">{children}</div>
        </div>
      )}
    </div>
  );
};

export const Menu = (props) => {
  const { className, children } = props;
  const [menu, setMenu] = useState(false);
  const classes = classNames('menu', { active: menu }, className);
  const [setScrollLock] = useWindowLockScroll();

  const onClickHandler = () => {
    setMenu(!menu);
    setScrollLock(!menu);
  };

  return (
    <div className={classes}>
      {menu && <div className="menu-overlay" onClick={onClickHandler} />}
      <div className="menu-icon" role="button" tabIndex={0} onKeyDown={onClickHandler} onClick={onClickHandler}>
        <MenuIcon />
      </div>
      <div className="menu-items">{children}</div>
    </div>
  );
};

Menu.Item = MenuItem;
