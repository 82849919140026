import React from 'react';

export const SelectField = (props) => {
  const { type, registeredField, options, onChange, onBlur, placeholder } = props;

  return (
    <select {...registeredField} type={type} placeholder={placeholder} onBlur={onBlur} onChange={onChange}>
      {placeholder && (
        <option value="" disabled selected>
          {placeholder}
        </option>
      )}
      {options?.length > 0 &&
        options?.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
    </select>
  );
};
