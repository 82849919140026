import './CarouselMulti.scss';

export const CarouselMulti = {
  dots: true,
  arrows: false,
  infinite: false,
  responsive: [
    {
      breakpoint: 3000,
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: 700,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 300,
      settings: { slidesToShow: 1 },
    },
  ],
};
