import React from 'react';
import { classNames } from 'helpers';
import './Mask.scss';

export const Mask = props => {
  const { className } = props;
  const classes = classNames('mask', className);
  return (
    <div className={classes}>
      <svg
        viewBox="0 0 100 100"
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        fill="white"
      >
        <path d="M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z" />
      </svg>
    </div>
  );
};
