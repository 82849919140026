export const validate = (name, value) => {
  let error;

  if (!value) return null;

  if (name === 'fullname' || name === 'firstname' || name === 'lastname') {
    const regex = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
    const message = 'Please enter a valid name.';
    if (!regex.test(value)) error = message;
  }

  if (name === 'email') {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const message = 'Please enter a valid email address.';
    if (!regex.test(value)) error = message;
  }

  if (name === 'phone') {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const message = 'Please enter a valid phone number.';
    if (!regex.test(value)) error = message;
  }

  if (name === 'website') {
    const regex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port
      '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    const message = 'Please enter a website url.';
    if (!regex.test(value)) error = message;
  }

  return error;
};
