import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'helpers';
import './MenuIcon.scss';

export const MenuIcon = (props) => {
  const { className } = props;
  const classes = classNames('MenuIcon', className);

  return (
    <div className={classes}>
      <div className="MenuIconContainer">
        <span className="MenuIconItem MenuIconTop" />
        <span className="MenuIconItem MenuIconBottom" />
      </div>
    </div>
  );
};

MenuIcon.propTypes = {
  // menu: PropTypes.string.isRequired,
  className: PropTypes.string,
};

MenuIcon.defaultProps = {
  className: null,
};
