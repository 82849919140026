import React from 'react';
import { Container } from 'components';
import { classNames } from 'helpers';
import './Heading.scss';

export const Heading = (props) => {
  const { className, title, subtitle, description } = props;
  const classes = classNames('heading', className);
  return (
    <div className={classes}>
      {title && (
        <div className="heading-title">
          <h5>{title.text}</h5>
        </div>
      )}

      <div className="heading-container">
        {subtitle && (
          <div className="heading-subtitle">
            <h2>{subtitle.text}</h2>
          </div>
        )}

        {description && (
          <div className="heading-description">
            <p className="subtitle">{description.text}</p>
          </div>
        )}
      </div>
    </div>
  );
};
