import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import './FileUpload.scss';

export const FileUpload = (props) => {
  const { setValue, setError, control, clearErrors } = useFormContext();
  const { type, label, name } = props;

  return (
    <section className="container">
      <Controller
        render={({ onChange }) => (
          <Dropzone
            onChange={(e) => onChange(e.target.files[0])}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            name={name}
          />
        )}
        name={name}
        control={control}
        defaultValue=""
      />
    </section>
  );
};

const Dropzone = ({ onChange, setValue, setError, clearErrors, name }) => {
  const onDrop = async (acceptedFiles) => {
    // Do something with the files
    // 10MB Limit
    if (acceptedFiles[0]?.size === undefined || acceptedFiles[0]?.size > 10000000) {
      setError(name, { type: 'too-large', message: 'File needs to be less than 10mb and a PNG, PDF or JPG' });
      return;
    }
    setValue(name, acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, acceptedFiles, isFocused, isDragAccept } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg'],
      'application/pdf': ['.pdf'],
    },
  });

  const hasFile = acceptedFiles.length > 0;
  return (
    <div
      {...getRootProps({ className: `dropzone ${(isFocused || isDragAccept) && 'focused'} ${hasFile && 'accepted'}` })}
    >
      <input
        {...getInputProps({
          onChange,
        })}
      />
      {hasFile ? <p>{acceptedFiles[0].path}</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
    </div>
  );
};
