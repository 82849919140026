import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DefaultInput, TextArea, SelectField, FileUpload } from 'components';
import './styles.scss';

export const FormField = (props) => {
  const { type = 'text', name, label, onChange, onBlur, validation } = props;

  const formFieldsLookup = {
    email: DefaultInput,
    phone: DefaultInput,
    text: DefaultInput,
    number: DefaultInput,
    date: DefaultInput,
    select: SelectField,
    textarea: TextArea,
    fileupload: FileUpload,
  };

  const SelectedFormField = (additionalProps) =>
    React.createElement(formFieldsLookup?.[type], { ...props, ...additionalProps });

  const {
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const registeredField = register(name, validation);

  const onChangeHandler = (e) => {
    registeredField?.onChange(e);
    if (errors?.formSubmission) clearErrors('formSubmission');
    if (onChange) return onChange(e, watch(name));
  };

  const onBlurHandler = (e) => {
    registeredField?.onBlur(e);
    if (onBlur) return onBlur(e);
  };

  const fieldError = errors?.[name];
  const fieldErrorClass = fieldError ? 'error-active' : '';

  return (
    <div className={`form-field ${fieldErrorClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <SelectedFormField registeredField={registeredField} onChange={onChangeHandler} onBlur={onBlurHandler} />
      {fieldError && <span className="error">{fieldError?.message}</span>}
    </div>
  );
};
