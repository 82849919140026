import React from 'react';
import { classNames } from 'helpers';
import { Image } from '../Image/Image';
import './Avatar.scss';

const getInitials = (name) => {
  if (!name) return null;
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) initials += names[names.length - 1].substring(0, 1).toUpperCase();
  return initials;
};

export const Avatar = (props) => {
  const { className, name, image } = props;
  const classes = classNames('Avatar', className);
  return (
    <div className={classes}>
      {image && image.localFile && <Image image={image} />}
      {name && (
        <div className="AvatarContainer">
          <span>{getInitials(name)}</span>
        </div>
      )}
    </div>
  );
};
