import React from 'react';
import { icons } from 'icons';
import { toSnakeCase, classNames } from 'helpers';
import * as iconsPath from './IconsPath';
import './Icon.scss';

export const Icon = props => {
  const { className, title, type = 'img' } = props;
  const classes = classNames(className, 'icon');
  if (!title) return null;
  const iconsLibrary = type === 'img' ? icons : iconsPath;
  const Source = iconsLibrary[toSnakeCase(title)];

  if (!Source) return null;
  const icon =
    type === 'img' ? (
      <img src={Source.default} title={title} />
    ) : (
      <svg viewBox={`0 0 ${Source.width} ${Source.height}`} title={title}>
        <path d={Source.path} />
      </svg>
    );
  return <div className={classes}>{icon}</div>;
};
