export const Chevron = {
  width: 32,
  height: 32,
  path: 'M18.629 15.997l-7.083-7.081L13.462 7l8.997 8.997L13.457 25l-1.916-1.916z',
};

export const Quote = {
  width: 30,
  height: 30,
  path:
    'M 5 4 C 3.895 4 3 4.895 3 6 L 3 14 C 3 15.105 3.895 16 5 16 L 9.0410156 16 C 9.0659476 17.786225 8.8912171 21.613615 7.1621094 24.453125 C 7.1627399 24.453222 7.1634317 24.453029 7.1640625 24.453125 A 1.0003907 1.0003907 0 0 0 8.5996094 25.800781 C 8.5996094 25.800781 13 22.444444 13 16 L 13 6 C 13 4.895 12.105 4 11 4 L 5 4 z M 19 4 C 17.895 4 17 4.895 17 6 L 17 14 C 17 15.105 17.895 16 19 16 L 23.041016 16 C 23.065948 17.786225 22.891217 21.613615 21.162109 24.453125 C 21.162109 24.453125 21.164062 24.453125 21.164062 24.453125 A 1.0003905 1.0003905 0 0 0 22.599609 25.800781 C 22.599609 25.800781 27 22.444444 27 16 L 27 6 C 27 4.895 26.105 4 25 4 L 19 4 z',
};
