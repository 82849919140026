import React from 'react';
import { classNames } from 'helpers';
import './Scroll.scss';

export const Scroll = props => {
  const { className } = props;
  const classes = classNames('Scroll', className);
  return (
    <div className={classes}>
      <div className="mousey">
        <div className="scroller" />
      </div>
    </div>
  );
};
