import React from 'react';
import { classNames } from 'helpers';
import './Section.scss';

export const Section = props => {
  const { className, color, children, frame, ...extraProps } = props;
  const classes = classNames('section', className, color);
  const section = (
    <section {...extraProps} className={classes}>
      {children}
    </section>
  );
  return frame ? (
    <div {...extraProps} className="section-frame">
      {section}
    </div>
  ) : (
    section
  );
};
