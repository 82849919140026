import { CarouselGallery } from './CarouselGallery/CarouselGallery';
import { CarouselMulti } from './CarouselMulti/CarouselMulti';
import { CarouselDefault } from './CarouselDefault/CarouselDefault';
import { CarouselWizard } from './CarouselWizard/CarouselWizard';
import { CarouselSingle } from './CarouselSingle/CarouselSingle';

export const CarouselLayouts = {
  Default: CarouselDefault,
  Gallery: CarouselGallery,
  Multi: CarouselMulti,
  Wizard: CarouselWizard,
  Single: CarouselSingle,
};
