import React from 'react';
import { Wrapper, Logo, Menu } from 'components';
import { classNames } from 'helpers';
import './Header.scss';

const servicesArray = [
  'event-and-festival-cleaning',
  'sustainable-waste-management-services',
  'sanitation-and-disinfection-services',
  'sustainability-auditing-and-reporting',
];

const sortLinks = (links, sortArray) => {
  const nodes = links && links.nodes;
  const sortedNodes = nodes.sort((nodeA, nodeB) => {
    const indexA = sortArray.findIndex((nodeUID) => nodeUID === nodeA.uid);
    const indexB = sortArray.findIndex((nodeUID) => nodeUID === nodeB.uid);
    if (indexA < indexB) return -1;
    if (indexB > indexA) return 1;
    return 0;
  });
  return { nodes: sortedNodes };
};

const Links = ({ links, path }) => {
  const { nodes } = links;
  return nodes.map((node, index) => {
    if (node && node.uid === 'home') return null;
    return (
      <Menu.Item
        key={index}
        text={node.data.title.text || node.uid}
        to={path ? `/${path}/${node.uid}` : `/${node.uid}`}
      />
    );
  });
};

export const Header = (props) => {
  const { className, isHero, hasForm } = props;
  const { site, ...content } = props.data;
  const { siteSlug } = site.siteMetadata;
  const classes = classNames('header', { isHero }, siteSlug, className);
  return (
    <header className={classes}>
      <Wrapper className="header-wrapper">
        <div className="header-logo">
          <Logo />
        </div>

        <Menu className={{ isHero }}>
          <Menu.Item text="About us">
            <Menu.Item to="/about-us" text="About Us" />
            <Menu.Item to="/team" text="Meet the Team" />
          </Menu.Item>
          <Menu.Item text="Services" show={content.location.nodes.length > 0}>
            <Links path="service" links={sortLinks(content.service, servicesArray)} />
          </Menu.Item>
          <Menu.Item to="/projects" text="Projects" show={content.page.nodes.some((node) => node.uid === 'projects')} />
          <Menu.Item text="Find us" show={content.location.nodes.length > 0}>
            <Links links={content.location} />
          </Menu.Item>
          <Menu.Item to="/careers" text="Careers" show={content.page.nodes.some((node) => node.uid === 'careers')} />
          <Menu.Item to="/blog" text="Blog" show={content.page.nodes.some((node) => node.uid === 'blog')} />
          <Menu.Item className="button outline" to="/contact-us" text="Contact" />
        </Menu>
      </Wrapper>
    </header>
  );
};
